import React from 'react';
import PropTypes from 'prop-types'
import {Popup} from '@partssourceinc/react-ui-core';
import {connect} from 'react-redux';
import * as SettingsStore from 'stores/Settings';

@connect((state) => ({network: state.network, settings: state.settings}), SettingsStore.actionCreators)
export default class PopupAdmin extends React.Component {
    static propTypes = {
        confirmText: PropTypes.string,
        cancelText: PropTypes.string,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        className: PropTypes.string,
        hideButtons: PropTypes.bool,
        disableClose: PropTypes.bool,
        inlineHeader: PropTypes.bool,
        disableConfirm: PropTypes.bool,
        loading: PropTypes.bool,
        children: PropTypes.element,
        showThirdButton: PropTypes.bool,
        onThirdButtonClick: PropTypes.func,
        thirdButtonText: PropTypes.string,
        thirdButtonLoading: PropTypes.bool,
    };
  
    constructor(props) {
        super(props);
    }

    render() {
        let {overrideAdminRole, hideButtons, network: {admin: {permissions}}, ...rest} = this.props;

        if (location.pathname.indexOf('/admin') === 0 && !overrideAdminRole && !permissions.fullAdmin) {
            hideButtons = true;
        }

        return <Popup hideButtons={hideButtons} {...rest} />
    }
}
